import $ from 'jquery';
import axios from 'axios';

// What /report/filter-presence.json responds with, the string key is
// something like 'facet_12`
type FilterPresenceResponse = Record<string, number[]>;

/**
 * Send (through AJAX) the current overall filter form with a specified group, to get back
 * a list of ids for items in the current group that are present when the filter is applied
 *
 * @param panel The current accordion panel on the filters page
 */
export function updateFilterPresence(panel: JQuery) {
    axios.post<FilterPresenceResponse>(
        '/report/filter-presence.json',
        panel.closest('form').formSerialize(),
        {
            params: {
                step: panel.closest('form').attr('data-report-step'),
                group: panel.attr('data-filter-group'),
            },
        }
    ).then((response) => {
        panel.find('.filter-present').removeClass('filter-present');

        Object.entries(response.data)
            .forEach(([section, ids]) => {
                ids.forEach((id) => {
                    $('input[name="' + section + '[]"][value=' + id + ']')
                        .closest('.filter-item')
                        .addClass('filter-present');
                });
            });

        panel.attr('invalid', '0');
    });
}
