import axios from 'axios';
import { showNotifications } from '@/truserve/notifications';
import { initBehaviors } from '@/truserve/general';

export function init() {
    /**
     * Setup a button to submit the form this button is in asynchronously.  The controller
     * will return a JSON response indicating some things it may want the browser to do.
     *
     */
    $('body').on(
        'click',
        '.ts-ajax-submit',
        function () {
            const button = $(this);
            button.prop('disabled', true); // prevent a double-submit

            const form = button.closest('form');
            axios({
                method: (form.attr('method') || 'post').toLowerCase(),
                url: form.attr('action'),
                data: form.formSerialize(),
            }).then((response) => {
                // The controller wants the browser to go to another location
                if (response.data.redirect) {
                    window.location = response.data.redirect;
                    return;
                }

                // The controller wants the browser to show some notifications
                if (response.data.notifications) {
                    showNotifications(response.data.notifications);
                }

                // The controller wants some non-HTML elements updated
                if (response.data.updates) {
                    Object.entries(response.data.updates).forEach(([id, value]) => {
                        const elem = $('#' + id);
                        elem.fadeOut(200, () => {
                            elem.text(value as string);

                            elem.fadeIn(200);
                        });
                    });
                }

                // The controller wants some HTML elements updated
                if (response.data.htmlUpdates) {
                    Object.entries(response.data.htmlUpdates).forEach(([id, value]) => {
                        const elem = $('#' + id);
                        elem.fadeOut(200, () => {
                            elem.html(value as string);

                            elem.fadeIn(200);
                        });
                    });
                }

                const modal = form.closest('.modal');

                if (modal.length) {
                    // There was a modal dialog associated with this form

                    if (response.data.new_form) {
                        // The controller sent a replacement form, presumably with
                        // validation messages, replace form in DOM and re-wire behaviors
                        modal.html(response.data.new_form);
                        initBehaviors(modal);

                        // Put the cursor on the first visible input of the newly shown form.
                        modal.find('input, textarea').not('input[type=hidden]').first().trigger('focus');
                    } else {
                        // The controller didn't send a new form, so hide the modal
                        // and call it a success
                        modal.modal('hide');
                        modal.trigger('truserve.modal.success', response);
                    }
                }

                // re-enable the button that triggered this submit
                button.prop('disabled', false);
            });
        }
    );
}
