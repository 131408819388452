/*
 * Helper for styled file input controls from:
 *
 *   http://www.abeautifulsite.net/blog/2013/08/whipping-file-inputs-into-shape-with-bootstrap-3/
 */

$(document).on('change', '.btn-file :file', function () {
    const input = $(this);
    const numFiles = input.get(0).files ? input.get(0).files.length : 1;
    const label = (input.val() as string).replace(/\\/g, '/').replace(/.*\//, '');

    input.trigger('fileselect', [numFiles, label]);
});

$('.btn-file :file').on('fileselect', function (event, numFiles, label) {
    const input = $(this).parents('.input-group').find(':text');

    if (input.length) {
        const log = numFiles > 1 ? numFiles + ' files selected' : label;

        input.val(log);
    }
});
