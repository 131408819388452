const EVENT_NAME = 'wms-form-change';

/**
 * Dispatch an event, that should bubble to the nearest form, which will
 * then enable the "Update" button on the form.
 *
 * @param elem
 */
export const triggerFormChanged = (elem: EventTarget) => {
    elem.dispatchEvent(new Event(EVENT_NAME, {
        bubbles: true,
    }));
};


/**
 * Setup typical forms to enable their update buttons if something changes
 *
 */
$('form').each((_idx, formElement) => {
    const form = $(formElement);
    const updateBtn = form.find('.ts-update');

    const enableSubmit = () => {
        if (updateBtn.prop('disabled')) {
            updateBtn.prop('disabled', false);

            $('.ts-opposite-update').prop('disabled', true);

            // Elements that have a data-opposite-popover attribute
            // should have that content added as a popover.
            //
            // Typically on a button that's now disabled, explaining
            // why it's disabled.
            $('[data-opposite-popover]')
                .each((_idx2, element) => {
                    const obj = $(element);
                    obj.wrap('<div></div>').parent().popover({
                        content: obj.attr('data-opposite-popover'),
                        trigger: 'hover',
                        placement: 'auto',
                        delay: {
                            show: 600, hide: 100,
                        },
                    });
                });
        }
    };

    if (updateBtn.length) {
        form.find('input, textarea, select').on('change', enableSubmit);

        form.on(EVENT_NAME, (evt) => {
            enableSubmit();
            evt.stopPropagation();
        });

        // Adapted from https://stackoverflow.com/questions/1948332/detect-all-changes-to-a-input-type-text-immediately-using-jquery
        form.find('input,textarea').each(function () {
            const elem = $(this);

            // Save current value of element
            let oldVal = elem.val();

            // Look for changes in the value
            elem.on('propertychange keyup input paste', () => {
                // If value has changed...
                if (oldVal !== elem.val()) {
                    oldVal = elem.val();
                    enableSubmit();
                }
            });
        });

        // or if there's a field error and the user needs to fix something
        if ($('.alert-link').length) {
            enableSubmit();
        }
    }
});
