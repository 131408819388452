import * as Sentry from '@sentry/browser';
import settings from '@/settings';

if (process.env.NODE_ENV === 'production') {
    Sentry.init(settings.sentry);
} else {
    // eslint-disable-next-line no-console
    console.log('Webpack Mode:', process.env.NODE_ENV);
    // eslint-disable-next-line no-console
    console.log('Not initializing Sentry');
}
