interface Notification {
    level: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
    items: string[];
}

/**
 * Show notifications (received through AJAX) at the top of the main page body
 *
 * @param notifications
 */
export function showNotifications(notifications: Notification[]) {
    notifications.forEach((note) => {
        const ul = $(`<div class="alert alert-${note.level}"><ul></ul></div>`)
            .prependTo('.body-area').find('ul');

        note.items.forEach((item) => {
            $('<li></li>').appendTo(ul).text(item);
        });
    });
}
