export function init() {
    /**
     * URL fields have a "View" button attached on the right, clicks on
     * that should open the URL value in the input field.
     *
     */
    $('body').on(
        'click',
        '.ts-url-field-button',
        function () {
            const inputGroup = $(this).closest('.input-group');
            const url = inputGroup.find('input').val();
            if (url) {
                window.open(url as string);
            }
        }
    );
}
