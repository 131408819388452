import '@/boot_sentry';
import '@/boot_jquery';
import '@/boot_axios';
import 'jquery-form';
import 'jquery-ui';
import 'bootstrap';
import 'popper.js';
import 'select2';

import axios from 'axios';
import { showActivityMap } from '@/truserve/activity_map';
import { fiscalYearRange, initBehaviors } from '@/truserve/general';
import { showModalDialog } from '@/truserve/modal_dialog';
import '@/truserve/jquery_extensions';
import '@/form_enable_update';
import '@/truserve/file_upload';
import * as ajaxForm from '@/truserve/ajaxForm';
import * as textFieldURL from '@/truserve/textFieldURL';
import * as truserveReport from '@/truserve/report';

// Moment needs to be called by JS directly in the page
window.moment = require('moment');

const components = $('[data-component]');
if (components.length) {
    import('@/component_embed')
        .then((component_embed) => {
            component_embed.setup(components);
        });
}


/**
 * Fix problem with jQuery UI datepicker only partially working
 * in Bootstrap modal (month/year drop-downs don't work)
 *
 * Taken from
 *  https://stackoverflow.com/questions/21059598/implementing-jquery-datepicker-in-bootstrap-modal/21088713#21088713
 *
 * Doesn't help with React-Bootstrap modal though, in that case you need to add
 *
 *   enforceFocus={false}
 *
 *  as a prop to the <Modal> component
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-empty-function
$.fn.modal.Constructor.prototype.enforceFocus = () => {};

function tsInit() {
    initBehaviors($(document));

    ajaxForm.init();
    textFieldURL.init();

    // In a navbar or nav tabs, mark the active tab and setup to submit
    // the form before switching tabs.
    $('.nav-tabs').tsNavMarkActive().tsNavSubmitFirst();

    // Setup demographic groups to show a sum at the bottom of each column, updating as necessary
    $('.ts-demographic-group').tsDemographicGroup();

    // Setup wizard prev/next buttons, and jumping between steps
    $('div.wizard').tsWizardActions();

    $('button[data-url]').on('click', function () {
        window.location.href = $(this).attr('data-url') as string;
    });

    $('[data-popup-url]').on('click', function () {
        showModalDialog($(this).attr('data-popup-url') as string);
        return false;
    });

    /* Clear a filter form (such as on Activity or Org list pages) and then submit */
    $('button.ts-clear-filter').on('click', function () {
        const form = $(this).closest('form');
        form.find('input').val('');
        form.trigger('submit');
    });

    // When a select2 of class ts-org-group-copy-src changes, lookup the org group members
    // and fill them into a ts-org-group-copy-dest in the same form.
    $('.ts-org-group-copy-src').on('select2:select', function (evt) {
        const frm = $(this).closest('form');

        axios.get<{value: number; label: string}[]>('/lookup/org-group-members.json', {
            params: {
                org_group_id: evt.params.data.id,
            },
        }).then((response) => {
            // Convert what the backend sends to default DOM Option objects
            //  https://developer.mozilla.org/en-US/docs/Web/API/HTMLOptionElement/Option
            const newOptions = response.data.map(
                (option) => new Option(option.label, option.value.toString(), true, true)
            );

            // Append each new org option to the orgs multi-selector
            const orgsSelector = frm.find('.ts-org-group-copy-dest');

            newOptions.forEach((option) => {
                orgsSelector.append(option);
            });
        });
    });
}

window.fiscalYearRange = fiscalYearRange;

window.truserve = {
    initReport: truserveReport.initReport,

    showActivityMap,

    showModalDialog,

    tsInit,
};
