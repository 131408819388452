type ActivityMarkers = Record<number, [number, number]>


/**
 * Grab coordinates for the first activity in the array, can't use 0 as the index because
 * they're indexed by activity_ids
 *
 * @param markers
 * @returns {*}
 */
function firstCoordinates(markers: ActivityMarkers) {
    return Object.values(markers)[0];
}


/**
 * Show a Leaflet map with markers for activities.
 *
 * @param activityMarkers
 */
export function showActivityMap(activityMarkers: ActivityMarkers) {
    const bounds = L.latLngBounds(Object.values(activityMarkers));

    const map = L.map('map', {
        center: [40, -100],
        zoom: 4,
    });

    if ((bounds.getSouth() !== bounds.getNorth()) && (bounds.getEast() !== bounds.getWest())) {
        map.fitBounds(bounds, {
            padding: [20, 20],
            maxZoom: 7,
        });
    } else {
        map.setView(firstCoordinates(activityMarkers), 6, { reset: true });
    }

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
        minZoom: 3,
        maxZoom: 7,
        id: 'your.mapbox.project.id',
        accessToken: 'your.mapbox.public.access.token',
    }).addTo(map);

    const markers = new L.MarkerClusterGroup({ maxClusterRadius: 40 });

    Object.entries(activityMarkers).forEach(([activityID, coordinates]) => {
        const marker = L.marker(coordinates);
        marker.bindPopup(document.getElementById('item_' + activityID));
        markers.addLayer(marker);
    });

    map.addLayer(markers);
}
