import { updateFilterPresence } from '@/truserve/filters';

/**
 * Send some data to the server, and have it come back to the client
 * as a file being downloaded.  Used for downloading CSV data on reports
 *
 * @param data
 * @param mimeType
 * @param fileName
 */
function downloadData(data: string, mimeType: string, fileName: string) {
    $('#hidden-relay-form').remove();

    const form = $('<form method="post" action="/relay-download"'
        + ' id="hidden-relay-form" style="display:none;"></form>')
        .appendTo('body');

    const token = $('#logout_form').find('input').val() as string;

    $('<input type="hidden" name="_token">').val(token).appendTo(form);
    $('<input type="hidden" name="data">').val(data).appendTo(form);
    $('<input type="hidden" name="mimeType">').val(mimeType).appendTo(form);
    $('<input type="hidden" name="fileName">').val(fileName).appendTo(form);

    form.trigger('submit');
    form.remove();
}


/**
 * Returns the first table that's
 * been selected as a CSV string. Inspired by an answer in
 * http://stackoverflow.com/questions/4639372/export-to-csv-in-jquery
 *
 * Try not to quote numbers, unless they begin with a 0 (and might be zipcodes).
 *
 * @returns {string}
 */
function tableToCSV(tables: JQuery<HTMLElement>): string {
    const csvData: string[] = [];

    tables.first()
        .find('tr')
        .each(function () {
            const tmpArr: string[] = [];

            $(this).find('th,td').each(function () {
                const t = $(this).text();
                if (t.match(/^[-1-9]?\d*\.?\d+$/)) {
                    tmpArr.push(t);
                } else {
                    tmpArr.push('"' + t.replace(/"/g, '""') + '"');
                }
            });

            csvData.push(tmpArr.join(','));
        });

    return csvData.join('\r\n') + '\r\n';
}


jQuery.fn.extend({

    /**
     * Setup demographic groups to keep an updated sum at the bottom of each column.
     *
     * @returns {*}
     */
    tsDemographicGroup() {
        const group = this as JQuery;

        const eventName = 'truserve.demographic-update';

        group.on(eventName, function () {
            const groupDiv = $(this);
            let sum = 0;

            groupDiv.find('.ts-demographic-field input').each(function () {
                const v = $(this).val();
                if (v) {
                    sum += parseInt(v as string, 10);
                }
            });

            groupDiv.find('.ts-demographic-sum').html(sum.toString());
        });

        group.find('.ts-demographic-field input').on('change', function () {
            $(this).closest('.ts-demographic-group').trigger(eventName);
        });

        // Set initial contents of group sums
        return group.trigger(eventName);
    },


    /**
     * Setup Multi-Term checkbox arrays to show or clear/hide terms underneath them.
     *
     * @returns {*}
     */
    tsMultiCheckboxTerms() {
        const group = this as JQuery<HTMLElement>;

        // Initial showing of lower level items based on what's checked
        //
        group.find('input:checked').each(function () {
            const item = $(this).closest('.ts-term-item');

            const termId = item.attr('data-termid');
            const parentId = item.attr('data-parentid');

            const selector = '[data-termid="' + parentId + '"]'
                + ',[data-parentid="' + termId + '"]'
                + ',[data-parentid="' + parentId + '"]';

            // show the parent, children, and siblings
            const others = group.find(selector);

            others.show();
        });

        // Setup to handle changes to checkboxes
        //
        group.find('input').on('change', function () {
            const checkbox = $(this);
            const item = checkbox.closest('.ts-term-item');

            if (checkbox.is(':checked')) {
                // Show children

                group.find(
                    '[data-parentid="' + item.attr('data-termid') + '"]'
                ).slideDown();
            } else {
                // Hide and uncheck and all descendants

                group.find(
                    '[data-path^="' + item.attr('data-path') + ' >> "]'
                ).slideUp()
                    .find('input')
                    .prop('checked', false);
            }
        });

        // Add a class to highlight terms that have children
        group.find('[data-parentid]').each(function () {
            const { parentid } = this.dataset;
            if (parentid) {
                $(`[data-termid=${parentid}]`).addClass('has-children');
            }
        });
    },


    /**
     * Mark tabs in a navbar as active if it has an <a> with the URL of the current page.
     *
     * @returns {*}
     */
    tsNavMarkActive() {
        (this as JQuery<HTMLElement>)
            .find('a')
            // eslint-disable-next-line consistent-return
            .each(function () {
                const e = $(this);
                if (e.attr('href') === window.location.pathname) {
                    e.addClass('active');
                    return false; // breaks out of jquery.each()
                }
            });

        return this;
    },


    /**
     * When trying to switch tabs, submit the form first if something has changed.
     *
     * @returns {*}
     */
    tsNavSubmitFirst() {
        (this as JQuery<HTMLElement>)
            .find('a')
            .on('click', function (event) {
                const form = $('#main-form');
                if (form.length) {
                    const btn = form.find('button[type=submit]');
                    if (!btn.prop('disabled')) {
                        form.append('<input type="hidden" name="_next_url" value="'
                            + $(this).attr('href')
                            + '">');
                        form.trigger('submit');
                        event.preventDefault();
                    }
                }
            });

        return this;
    },


    /**
     * Setup report filter accordion panel so that checking a checkbox within it causes
     * a rethink of which choices are present on this and other panels (when they open).
     *
     * @returns {*}
     */
    tsReportFilterGroup() {
        const groupDiv = this as JQuery<HTMLElement>;

        // When opening a collapsed panel, if the 'invalid' attr is 1, then query
        // the server to get updated info about what choices are present.
        groupDiv.on('show.bs.collapse', function () {
            const panel = $(this);
            if (panel.attr('invalid') === '1') {
                updateFilterPresence($(this));
            }
        });

        // When a checkbox is changed within a filter-group, mark the other
        // filter-groups as invalid, so we know to refresh their visibility
        // when they open.
        groupDiv.find('input')
            .on('change', function () {
                const checkbox = $(this);
                const group = checkbox.closest('div[data-filter-group]');

                $('div[data-filter-group]').attr('invalid', '1');
                group.attr('invalid', '0');

                if (group.find('input[type=radio]:checked').val() === 'and') {
                    updateFilterPresence(group);
                }
            });

        // Also refresh when switching between AND and OR filters
        groupDiv.find('input[type=radio]').on('click', function () {
            const checkbox = $(this);
            const group = checkbox.closest('div[data-filter-group]');

            $('div[data-filter-group]').attr('invalid', '1');
            updateFilterPresence(group);
        });

        // mark all groups as invalid initially
        groupDiv.attr('invalid', '1');

        return this;
    },


    /**
     * Setup report filter accordion panels to add descriptive text to
     * the accordion labels, along with a clear "x" button if there are
     * things clicked inside.
     *
     * @returns {*}
     */
    tsReportFilterPanel() {
        const eventName = 'truserve.filter-change';
        const cardDiv = this as JQuery<HTMLDivElement>;

        // Event handler that updates the text on the filter label and
        // adds/removes a "clear" button on the right
        cardDiv.on(eventName, function () {
            const card = $(this);
            const values: string[] = [];
            const heading = card.find('.card-header');

            heading.find('.ts-filter-description, .ts-clear-filter').remove();

            card.find('input.ts-text-filter').each(function () {
                const val = ($(this).val() as string).trim();
                if (val) {
                    values.push(val);
                }
            });

            card.find('input[type=checkbox]:checked').each(function () {
                values.push($(this).next('span').text().trim());
            });

            // Get the labels from select widgets
            card.find<HTMLOptionElement>(':selected')
                .each(function () {
                    values.push(this.text);
                });

            if (values.length) {
                card.find('.card-header').addClass('card-filter-active');
                $('<div class="ts-filter-description"></div>')
                    .text(values.join(' / '))
                    .appendTo(heading);

                $('<div class="ts-clear-filter">'
                    + '<i class="fas fa-times"></i>'
                    + '</div>')
                    .appendTo(heading)
                    .on('click', () => {
                        card.find('input.ts-text-filter').val('');
                        card.find('input[type=checkbox]').prop('checked', false);
                        card.find('select').val('').trigger('change');
                        card.trigger(eventName);

                        // mark all filter-groups as invalid, and refresh the open one.
                        $('div[data-filter-group]').attr('invalid', '1');
                        const openPanel = $('.collapse.show').first();
                        if (openPanel.length) {
                            updateFilterPresence(openPanel);
                        }
                    });
            } else {
                card.find('.card-header').removeClass('card-filter-active');
            }
        });

        // Fire the card's event handler whenever a checkbox inside is clicked.
        cardDiv.find('input,select').on('change', function () {
            $(this).closest('.card').trigger(eventName);
        });

        // Set initial contents of filter labels
        return cardDiv.trigger(eventName);
    },


    /**
     * Setup behaviors for buttons on charts/CSV tables on the report page
     *
     * @returns {*}
     */
    tsReportSummaryToggleButtons() {
        const divs = this as JQuery<HTMLDivElement>;

        divs.each(
            function () {
                const combo = $(this);
                if (!combo.find('.ts-summary-chart').length) {
                    // no chart, it's just a table, get rid of the chart stuff
                    combo.find('.ts-summary-data').show();
                    combo.find('.ts-show-summary-chart, .ts-show-summary-table').remove();
                    combo.find('.ts-summary-download-buttons .dropdown-divider').remove();
                    combo.find('.ts-summary-download-buttons').find('a').each(function () {
                        const a = $(this);
                        if (a.attr('data-format') !== 'csv') {
                            a.remove();
                        } else {
                            a.text('CSV/Excel version of this table');
                        }
                    });
                }
            }
        );

        divs.find('.ts-show-summary-table').on('click', function () {
            const btn = $(this);
            const div = btn.closest('div.ts-summary-combo');

            div.find('.ts-summary-chart')
                .fadeOut(400, () => {
                    div.find('.ts-summary-data').fadeIn();
                });

            btn.removeClass('btn-light')
                .addClass('btn-primary');

            div.find('.ts-show-summary-chart')
                .removeClass('btn-primary')
                .addClass('btn-light');
        });

        divs.find('.ts-show-summary-chart').on('click', function () {
            const btn = $(this);
            const div = btn.closest('div.ts-summary-combo');

            div.find('.ts-summary-data')
                .fadeOut(400, () => {
                    div.find('.ts-summary-chart').fadeIn();
                });

            btn.removeClass('btn-light')
                .addClass('btn-primary');

            div.find('.ts-show-summary-table')
                .removeClass('btn-primary')
                .addClass('btn-light');
        });

        divs.find('.ts-summary-download').on('click', function () {
            const anchor = $(this);
            const format = anchor.attr('data-format');
            const div = $(this).closest('div.ts-summary-combo');
            const fileName = div.find('h2')
                .text()
                .toLowerCase()
                .replace(/\s/g, '_'); // without an extension

            switch (format) {
                case 'csv': {
                    const csvContent = tableToCSV(div.find('.ts-summary-data'));
                    downloadData(csvContent, 'text/csv;charset=utf-8', fileName + '.csv');
                    break;
                }
                case 'png':
                case 'pdf':
                case 'svg': {
                    const chart = div.find('.ts-summary-chart').children().first();
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const fcChart = FusionCharts(chart.attr('id') as string);
                    fcChart.exportChart({
                        exportFilename: fileName,
                        exportFormat: format.toUpperCase(),
                    });
                    break;
                }
                default:
                    // Shouldn't happen
                    break;
            }
        });

        return this;
    },


    /**
     * Wire up next/prev buttons in a wizard
     *
     * @returns {*}
     */
    tsWizardActions() {
        const wizardDiv = this as JQuery<HTMLDivElement>;

        const activeStep = wizardDiv.find('ul.steps li.active');

        wizardDiv.find('.btn-next')
            .prop('disabled', activeStep.next().length === 0)
            .on('click', () => {
                $('#main-form').trigger('submit');
            });

        wizardDiv.find('.btn-prev')
            .prop('disabled', activeStep.prev().length === 0)
            .on('click', () => {
                $('#main-form')
                    .append('<input type="hidden" name="next_step" value="'
                        + activeStep.prev().attr('data-step')
                        + '">')
                    .trigger('submit');
            });

        // Make the completed steps clickable
        wizardDiv.find('ul.steps li').on('click', function () {
            const step = $(this);
            if (step.hasClass('complete')) {
                $('#main-form')
                    .append('<input type="hidden" name="next_step" value="'
                        + step.attr('data-step')
                        + '">')
                    .trigger('submit');
            }
        });

        return this;
    },
});
