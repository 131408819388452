import autosize from 'autosize';
import moment from 'moment';
import type { Options } from 'select2';
import 'jquery-ui/ui/widgets/datepicker';

/**
 * Figure out a date range for a Fiscal year, given a numeric month the FY starts on, assuming it
 * starts on the first of the month.
 *
 * NOTE: this is not used in the overall source, but is called from various sites'
 * config/truserve.php files under 'additional_date_ranges'
 *
 * @param startMonth 1=January, 2=Feb, ...
 * @param yearOffset 0 or omitted = current FY, -1 = last FY
 * @returns {Array}
 */
export function fiscalYearRange(startMonth: number, yearOffset?: number) {
    const fyStart = startMonth - 1; // moment uses 0-based numbering, January=0, Feb=1, ...
    const now = moment().month();
    let fy;

    if (now >= fyStart) {
        fy = [
            moment()
                .startOf('year')
                .add('month', fyStart),
            moment()
                .startOf('year')
                .add('year', 1)
                .add('month', fyStart)
                .subtract('day', 1),
        ];
    } else {
        fy = [
            moment()
                .startOf('year')
                .subtract('year', 1)
                .add('month', fyStart),
            moment()
                .startOf('year')
                .add('month', fyStart)
                .subtract('day', 1),
        ];
    }

    if (yearOffset) {
        fy[0] = fy[0].add('year', yearOffset);
        fy[1] = fy[1].add('year', yearOffset);
    }

    return fy;
}


/**
 * Initialize behaviors that might be found on a modal form loaded through AJAX.
 *
 * @param selection
 */
export function initBehaviors(selection: JQuery<Document|HTMLElement>) {
    selection.find('.ts-date').datepicker({ dateFormat: 'yy-mm-dd' });

    // On input fields marked with class ts-date-warn, show a message if the date is changed to
    // something more than a month old or more than a week into the future.
    //
    selection.find('.ts-date-warn').on('change', function () {
        const dateField = $(this);
        const d = dateField.val();
        dateField.parent().find('.ts-date-warning').remove();
        if (d) {
            if (d > moment().add('days', 7).format('YYYY-MM-DD')) {
                dateField.parent().append('<span class="ts-date-warning">'
                    + 'Please note: this date is more than a week in the future.'
                    + '</span>');
            } else if (d < moment().subtract('month', 1).format('YYYY-MM-DD')) {
                dateField.parent().append('<span class="ts-date-warning">'
                    + 'Please note: this date is more than a month in the past.'
                    + '</span>');
            }
        }
    });

    // Upgrade regular HTML select controls to Select2
    selection.find('select').each(function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const elem = this;

        // eslint-disable-next-line no-nested-ternary
        const minimumInputLength = elem.dataset.minimumInputLength
            ? parseInt(elem.dataset.minimumInputLength, 10)
            : (elem.dataset.ajaxUrl ? 1 : 0);

        const params : Options = {
            allowClear: true,
            // dropdownParent: elem.parentNode,
            minimumInputLength,
            placeholder: elem.dataset.placeholder || '--- None ---',
            theme: 'bootstrap4',
            width: '100%',
        };

        if (elem.multiple) {
            elem.name += '[]';
        }

        if (elem.dataset.ajaxUrl) {
            params.ajax = {
                url: elem.dataset.ajaxUrl,
                dataType: 'json',
                data(queryParams) {
                    return {
                        q: queryParams.term, // search term
                    };
                },
                processResults(data) {
                    return {
                        // The backend uses value and label properties, but select2 has its own
                        // way of doing things with id and text properties
                        //
                        results: data.map((option: {value : string | number, label: string}) => ({
                            id: option.value,
                            text: option.label || option.value,
                        })),
                    };
                },
            };
        }

        // Webpack - doesn't work with Vite:FIXME
        $(elem).select2(params);

        // When clicking on a select2, and there's a search field, move the cursor focus there.
        //
        $(elem).on('select2:open', () => {
            const input = document.querySelector<HTMLElement>('.select2-container--open .select2-search__field');
            if (input) {
                input.focus();
            }
        });
    });

    autosize(selection.find('textarea'));

    selection.find('.ts-multi-checkbox-terms').tsMultiCheckboxTerms();

    // Label report filters and refresh when filter checkbox changes
    selection.find('.filters .card').tsReportFilterPanel();
    selection.find('div[data-filter-group]').tsReportFilterGroup();

    // Handle buttons for loading saved reports
    selection.find('button[data-report-id]').on('click', function () {
        const btn = $(this);
        const f = btn.closest('form');
        f.find('input[name=report_id]').val(btn.attr('data-report-id') as string);
        f.trigger('submit');
    });

    selection.find('[data-content]').popover({
        trigger: 'hover',
        placement: 'auto',
        delay: { show: 600, hide: 100 },
    });

    // Keep users from selecting users for private access, but still having "All" as the type
    selection.find('.ts-privacy-group').each(function () {
        const grp = $(this);

        // Switch to Private radio button when something changes in user-select and it's non-empty
        grp.find('select').on('change', () => {
            if ($(this).find(':selected').length) {
                grp.find('input[value=1]').trigger('click');
            }
        });

        // Blank out user-select if "All" radio button clicked.
        grp.find('input[value=0]').on('click', () => {
            grp.find('select').val('').trigger('change');
            $(this).prop('checked', true);
        });
    });
}
